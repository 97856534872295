@import "~@/styles/variables";




















































































































































.contact-list {
  border: 2px solid $pp-border-blue;
  background: $pp-snowball;
  width: 12em;
}
.page-header {
  font-size: 3rem;
}
.disclaimer {
  font-size: 16px;
}
