@import "~@/styles/variables";
















































































































.med-table {
  table-layout: fixed;
  width: 100%;
  margin: 1em 0 3em 0;

  &:last-child {
    margin-bottom: 0;
  }

  thead {
    border-top: 3px solid $pp-primary-blue;
    color: $pp-primary-blue;

    &.paused {
      border-top: 3px dashed $pp-blue-grey;
      color: $pp-blue-grey;
    }
  }

  tr {
    border-bottom: 1px solid $pp-border-blue;
  }

  th {
    padding: 0.6em;
  }

  td {
    padding: 0.2em 0.6em;
  }
}

td.med-directions {
  font-size: 95%;
  line-height: 1.2;
}
